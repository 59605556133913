import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ContentWrapper from "styles/contentWrapper"

const miniSpot = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-mini.svg"
const bigSpot = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-big.svg"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background-image:  url(${bigSpot}), url(${miniSpot});
  background-repeat: no-repeat;
  background-size: 31rem 29rem, 3.9rem 3.4rem;
  background-position: 5% 0%;
  padding: 3rem 0;
  padding-bottom: 6rem;
  .section-title {
    margin-bottom: 1rem;
  }
  .section-subtitle {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.2rem;
    max-width: 48rem;
    margin: auto;
    padding: 0 1rem;
    span {
      font-weight: 600;
    }
  }

  h3 {
    margin-bottom: 0;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    margin-top: 3rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
    @media(min-width: ${({ theme }) => theme.breakpoints.md}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
    }
  }
`

const StyledItem = styled.a`
  background-image: linear-gradient(rgb(255, 255, 255), rgb(245, 245, 250));
  transition: transform 0.2s ease-out 0s, box-shadow 0.2s ease-out 0s;
  box-shadow: 0 1rem 2.5rem rgba(132, 156, 156, .15);
  border-radius: 1.5rem;
  background: white;
  display: flex;
  margin-bottom: 1.5rem;
  height: auto;
  @media(min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 10rem;
  }
  &:hover {
    text-decoration: none!important;
    transform: translateY(-5px);
    box-shadow: rgba(37, 44, 97, 0.15) 0px 10px 30px 0px, rgba(93, 100, 148, 0.2) 0px 4px 18px 0px;
  }
  .left {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 25%;
    border-right: 1px solid #c5c9e0;
    .logo {
      width: 4rem;
      @media(min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 6rem;
      }
    }
  }
  .right {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 1 75%;
    .top {
      line-height: 1.8rem;
      font-size: 1.5rem;
      letter-spacing: -.05rem;
      color: #00495d;
      text-decoration: none;
    }
    .bot {
      line-height: 1.85rem;
      font-size: 0.8rem;
      text-align: center;
      letter-spacing: -.05rem;
      color: #00495d;
      display: flex;
      justify-content: space-between;
      width: 100%;
      opacity: 0.5;
      flex-wrap: wrap;
    }
  }
`

const List = ({ content }) => {
  const { frontmatter, exports } = content[0].node
  const { items } = exports
  return (
    <StyledSection id="List">
      <h2 className="section-title">{frontmatter.title}</h2>
      <div className="section-subtitle">{frontmatter.subtitle}</div>
      <StyledContentWrapper>
        {items.map(({company, date, logo, link, prize}, key) => {
          const image = getImage(logo)
          return (
            <StyledItem href={link} key={key} target="blank" rel="nofollow noopener">
              <div className="left">
                <GatsbyImage image={image} alt={company} className="logo"/>
              </div>
              <div className="right">
                <div className="top">
                  {prize}
                </div>
                <div className="bot">
                  <span>{company}</span>
                  <span>{date}</span>
                  </div>
              </div>
            </StyledItem>
          )
        })}
      </StyledContentWrapper>
    </StyledSection>
  )
}

List.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default List
