import React, { useRef, useContext, useEffect } from "react"
import styled from 'styled-components';
import { motion, useAnimation } from "framer-motion"
import { useOnScreen } from "hooks/"



// import Footer from './footer';

const Container = styled.div`
  background-image: url('https://hrflow-ai.imgix.net/gradient-bg.webp');
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 3rem 1rem;
  padding-bottom: 10rem;
`

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
`
const Title = styled.div`
  margin-bottom: 2rem;
  color: #fff;
  font-size: 2.1rem;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -.115rem;
  @media (min-width: 600px) {
    font-size: 1.8rem;
  }
`



const ButtonWhite = styled.a`
  width: auto;
  height: auto;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.white};
  transition: 20ms ease-out;
  font-size: 1rem;
  padding: 0.7rem 1rem 0.8rem 1rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  max-height: 50px;
  @media (max-width: 600px) {
    width: 100%;
    min-width: 250px;
    margin-bottom: 1rem;
  }
`
   

const ButtonPrimary = styled(ButtonWhite)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 1rem;
  &:hover {
    background-color: rgba(36, 204, 212, 0.4);
  }
  @media (max-width: 600px) {
    margin-left: 0;
  }
`
const Divider = styled.div`
  border-left: 1px solid #fff;
  height: 2.65rem;
  opacity: .57;
  margin: 0 1.5rem 0 1.5rem;
  @media(max-width: 600px) {
    display: none;
  }
`



const Trial = () => {
  const controls = useAnimation()
  const ref = useRef()
  const onScreen = useOnScreen(ref)
  useEffect(() => {
    if (onScreen) controls.start({ x: 0 })
  }, [controls, onScreen])
  return (
    <Container>
      <Div>
        <Title>
            Getting started is easy 
        </Title>
        <motion.div
          className="group"
          ref={ref}
          initial={{ x: 30 }}
          transition={{ duration: 0.5 }}
          animate={controls}
        >
          <ButtonPrimary  href="/book-us/">talk to an expert</ButtonPrimary>
          <Divider></Divider>
          <ButtonWhite href="/signup/">Get started</ButtonWhite>
        </motion.div>
      </Div>
    </Container>
  )
}

export default Trial;