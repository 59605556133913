import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ContentWrapper from "styles/contentWrapper"

const miniSpot = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-mini.svg"
const bigSpot = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-big.svg"
const miniSpotRight = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-mini-right.svg"
const bigSpotRight = "https://hrflow-ai.imgix.net/backgrounds/secondary-spot-big-right.svg"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
  .section-title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  .section-subtitle {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.2rem;
    span {
      font-weight: 600;
    }
  }

  h3 {
    margin-bottom: 0;
  }

  .item {
    padding: 4rem 0;
    h2 {
      padding: 0;
    }
    &:nth-of-type(odd) {
      background-image:  url(${bigSpot}), url(${miniSpot});
      background-repeat: no-repeat;
      background-size: 31rem 29rem, 3.9rem 3.4rem;
      background-position: 5% 0%;
      .item__inner {
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }
    }

    &:nth-of-type(even) {
      background-image:  url(${bigSpotRight}), url(${miniSpotRight});
      background-repeat: no-repeat;
      background-size: 31rem 29rem, 3.9rem 3.4rem;
      background-position: 90% 0, 93% 0;
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    h2 {
      padding: 0;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: row;
      justify-content: space-between;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .logo {
      margin-bottom: 1rem;
    }

    .text-content {
      width: 100%;
      max-width: 31.25rem;
      h2 {
        span {
          color: ${({ theme }) => theme.colors.secondary}
        }
      }
      p {
        font-family: ${({ theme }) => theme.fonts.plainLight};
      }
      .author {
        font-weight: 600;
      }
    }
    .image-content {
      width: 100%;
      max-width: 40rem;
      margin-top: 4rem;
      margin-left: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left:  ${props => props.reversed ? '2rem' : '0' };
      }
    }
    .about-author {
      border-radius: ${({ theme }) => theme.borderRadius};
      box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
      }
    }
  }
`

const StyledVideo = styled.div`
  .iframe-wrapper-small, .iframe-wrapper-large {
    margin-bottom: 2rem;
  }
  .iframe-wrapper-small {
    display: none;
  }
  .iframe-wrapper-large {
    display: block;
    width: 100%;
    max-width: 60rem;
    margin: auto;
    iframe {
      border-radius: 6px;
      box-shadow: 0 1rem 2.5rem rgba(132, 156, 156, 0.15);
      background-color: white;  
    }
  }
  @media (max-width: 1200px) {
    flex: 1 1 100%;
  }
  @media (max-width: 600px) {
    .iframe-wrapper-small {
      display: block;
    }
    .iframe-wrapper-large {
      display: none;
    }
  }
`

const Videos = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { items } = exports
  return (
    <StyledSection id="videos">
      <h2 className="section-title">{frontmatter.title}</h2>
      {items.map(({ title, text, author, video }, key) => {
        return (
          <div className="item" key={key}>
            <StyledContentWrapper className="item__inner">
              <div className="inner-wrapper">
                <div className="text-content">
                  <h2>{title}</h2>
                  <p>{text}</p>
                  <div className="author">{author}</div>
                </div>
              </div>
              <div className="image-content">
                <StyledVideo>
                  <div className="iframe-wrapper-large">
                    <iframe width="100%" height="450" src={`//www.youtube.com/embed/${video}`} frameBorder="0" allowFullScreen></iframe>
                  </div>
                  <div className="iframe-wrapper-small">
                    <iframe width="100%" height="157.5" src={`//www.youtube.com/embed/${video}`}  frameBorder="0" allowFullScreen></iframe>
                  </div>
                </StyledVideo>
              </div>
            </StyledContentWrapper>
          </div>
        )
      })}
    </StyledSection>
  )
}

Videos.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Videos
